import { decamelizeKeys, camelizeKeys } from 'humps'

const camelizeTransform = {
  request: ({ params, payload, ...other }) => ({
    ...other,
    payload: decamelizeKeys(payload),
    params: decamelizeKeys(params),
  }),
  response: ({ body, ...other }) => ({
    ...other,
    body: camelizeKeys(body),
  }),
}

export default camelizeTransform
