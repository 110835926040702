import { createApiHook, createFetcher } from '@foundation-lib/api'

const useApi = createApiHook({ fetcher: createFetcher() })

const useSendEmail = () =>
  useApi({
    url: 'https://usebasin.com/f/7888b38d9a6f.json',
    method: 'post',
    lazy: true,
    config: {
      withCredentials: false,
    },
  })

export default useSendEmail
