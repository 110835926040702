import axios from 'axios'
import merge from 'lodash.merge'

import camelizeTransform from './transforms/camelizeTransform'
import defaultUrl from './transforms/defaultUrl'

export const defaultRequestTransforms = [
  camelizeTransform.request,
  defaultUrl.request,
]

export const defaultResponseTransforms = [camelizeTransform.response]

export const defaultConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseEncoding: 'utf8',
  withCredentials: true,
}

const createFetcher = ({
  requestTransforms = defaultRequestTransforms,
  responseTransforms = defaultResponseTransforms,
  rootUrl = '',
  staticConfig,
  errorHandler = () => {},
} = {}) => {
  const axiosConfig = merge(defaultConfig, staticConfig)
  const api = axios.create(axiosConfig)

  const createFetcherResponse = ({ data, headers, status, statusText }) => {
    // always return "fetcher response interface" for interoperability with other dependencies
    return { body: data, headers, status, statusText }
  }

  const applyTransforms = (transforms, data) =>
    transforms.reduce((req, fn) => fn({ ...req, rootUrl }), data)

  const transformResponse = response =>
    applyTransforms(responseTransforms, createFetcherResponse(response))

  const convertToAxiosRequest = ({ payload, ...other }) => ({
    data: payload,
    ...other,
  })

  const sendRequest = async request => {
    const axiosRequest = convertToAxiosRequest(
      applyTransforms(requestTransforms, request),
    )
    try {
      const axiosResponse = await api(axiosRequest)
      return transformResponse(axiosResponse)
    } catch (err) {
      // eslint-disable-next-line fp/no-mutation
      err.response = transformResponse(err.response)

      if (errorHandler) errorHandler(err)
      throw err
    }
  }

  const get = async (url, config) =>
    sendRequest({ method: 'get', url, ...config })

  // This method is named destroy because `delete` is a keyword in JS. It is ok
  // to have the name `delete` on the returned object as a key though.
  const destroy = async (url, config) =>
    sendRequest({ method: 'delete', url, ...config })

  const patch = async (url, payload, config) =>
    sendRequest({ method: 'patch', url, payload, ...config })

  const post = async (url, payload, config) =>
    sendRequest({ method: 'post', url, payload, ...config })

  const put = async (url, payload, config) =>
    sendRequest({ method: 'put', url, payload, ...config })

  return { delete: destroy, get, patch, post, put }
}

export default createFetcher
