import { usePromise } from '@foundation-lib/promise'

const createApiHook = ({ fetcher }) => {
  const hook = ({ url, method = 'get', config, lazy = false }) => {
    const { start, response, ...promiseLifecycleProperties } = usePromise(
      ({ cancelToken, imperativeArgs }) => {
        const [payload = {}] = imperativeArgs
        const configWithCancelToken = { ...config, cancelToken }
        switch (method.toLowerCase()) {
          case 'delete':
            return fetcher.delete(url, configWithCancelToken)
          case 'get':
            return fetcher.get(url, configWithCancelToken)
          case 'patch':
            return fetcher.patch(url, payload, configWithCancelToken)
          case 'post':
            return fetcher.post(url, payload, configWithCancelToken)
          case 'put':
            return fetcher.put(url, payload, configWithCancelToken)
          default:
            throw new Error('Unsupported method type')
        }
      },
      [url, method, JSON.stringify(config)],
      { lazy },
    )

    return {
      body: response && response.body,
      headers: response && response.headers,
      send: start,
      ...promiseLifecycleProperties,
    }
  }

  return hook
}

export default createApiHook
