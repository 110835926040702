import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import ContactPageBody from './ContactPageBody'

const GatsbyContactPage = () => (
  <StandardPage>
    <SEO title="Contact" />
    <ContactPageBody />
  </StandardPage>
)

export default GatsbyContactPage
