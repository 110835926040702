import React from 'react'
import * as Yup from 'yup'
import { Button, MarginOffset } from '@foundation-lib/ui'
import {
  TextInput,
  SubmitButton,
  Form,
  FormWrapper,
} from '@foundation-lib/formik-form'
import useSendEmail from '../utils/useSendEmail'

export default () => {
  const {
    status: { isLoading, hasError },
    body,
    send,
    reset,
  } = useSendEmail()

  return (
    <>
      <h2>Call us</h2>
      <p>
        If you would like to make a purchase over the phone or would like
        pricing on bulk purchases (24 copies or more of one of our books),
        please call us at <b>(740) 831-4249</b>.
      </p>
      <h2>E-mail us</h2>
      {body?.success ? (
        <>
          <p>Your submission was successful! We will be in touch soon.</p>
          <p>
            <Button
              onClick={e => {
                e.preventDefault()
                reset()
              }}
            >
              Click here to send another message
            </Button>
          </p>
        </>
      ) : (
        <FormWrapper
          onSubmit={send}
          initialValues={{}}
          validationSchema={Yup.object({
            name: Yup.string().label('Name').required(),
            email: Yup.string().label('E-mail address').required(),
            message: Yup.string().label('Message').required(),
          })}
          isSubmitting={isLoading}
        >
          <Form>
            <MarginOffset>
              <TextInput width="large" label="Name" name="name" />
              <TextInput width="large" label="E-mail address" name="email" />
              <TextInput
                width="full"
                label="Subject"
                name="subject"
                fullWidth
              />
              <TextInput
                width="full"
                label="Message"
                name="message"
                multiline
                fullWidth
              />
            </MarginOffset>
            {hasError && (
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                <br />
                There was an error with your submission. Please try again later.
              </div>
            )}
            <br />
            <SubmitButton isSubmitting={isLoading}>Send</SubmitButton>
          </Form>
        </FormWrapper>
      )}
    </>
  )
}
